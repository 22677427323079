import BaseModel from './BaseModel';
import ApplicationModel from './ApplicationModel';
import CategoryResponseModel from '../../domains/applications/models/CategoryResponseModel';
import PlanModel from '../../domains/applications/models/PlanModel';
import ScopeResponseModel from '../../domains/applications/models/ScopeResponseModel';
import { WebhookResponse } from '@/domains/applications/types/create-app/createApp.enum';

class FullApplicationProgressResponseModel extends BaseModel {
  public payload: AppPayloadInterface | null;

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export interface AppPayloadInterface {
  app: ApplicationModel;
  categories?: CategoryResponseModel[];
  category: CategoryResponseModel;
  development: string | null; // check other types
  headScripts: [];
  isAppInDevelopmentStore: boolean;
  plans: PlanModel[];
  scopeGroups: ScopeResponseModel[];
  tokens: {
    id: number;
    secret: string;
  };
  webhooks: Record<string, WebhookResponse[]>;
  webhooksAvailable: string[];
  app_url: string;
  plans_update_status: UpdateStatusEnum;
  general_settings_update_status: UpdateStatusEnum;
  app_snippets: SnippetItemInterface[];
}

export enum UpdateStatusEnum {
  None = '',
  Processed = 'processed',
  Pending = 'pending',
}

export interface SnippetItemInterface {
  id: number;
  name: string;
  location: string;
  snippet: string;
}

export default FullApplicationProgressResponseModel;
